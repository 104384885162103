import React from 'react'
import PageHeader from './components/globals/pageHeader'
import ProvisionedServices from './components/services/provisionedServices'
import WhatWeDo from './components/home/whatWeDo'
import Quality from './components/home/quality'
import Testimonials from './components/home/testimonials'

const Services = () => {
    return (
        <div className='flex flex-col gap-5'>
            <PageHeader page="Services" />
            <ProvisionedServices />
            <div className='my-5 pt-5'>
                <Quality />
            </div>
            <WhatWeDo page="service" />
            <Testimonials />
        </div>
    )
}

export default Services