import React, { useState } from "react";
import CustomButton from "../../../components/buttons";
import videoBG from "../../../assets/video2.mp4";
import dfimage from "../../../assets/alot_of_boxes.jpg";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const AroundTheWorld = () => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setIsVideoLoaded(true);
    };

    return (
        <div className="flex flex-col gap-10 mt-10 px-4 md:px-10 lg:px-20">
            <div className="grid grid-cols-1 md:grid-cols-12">
                {/* Text Section */}
                <div className="col-span-1 md:col-span-6 flex flex-col gap-5 py-10 md:py-20">
                    <span className="text-[20px] md:text-[34px] font-bold w-full md:w-[80%]">
                        Kanztainer - <span className="text-custom-yellow">International</span> General Trading Company
                    </span>
                    <span className="flex flex-col gap-5 w-full md:w-[80%]">
                        <span className="text-[12px] md:text-sm">
                            Kanztainer is a leading global provider of empty container solutions, supporting industries and facilitating the international exchange of goods through land transport.
                        </span>
                        <span className="text-[12px] md:text-sm">
                            Our value-added services ensure the smooth movement of containers, keeping supply chains efficient, streamlined, and ready for continuous operation.
                        </span>
                    </span>
                    <div className="flex items-center justify-start mt-2">
                        <CustomButton text="More About Us" type="blue" />
                    </div>
                </div>

                {/* Video Section */}
                <div className="col-span-1 md:col-span-6 bg-slate-300 relative rounded-lg overflow-hidden">
                    {isVideoLoaded && (
                        <div className="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center">
                            <Spin spinning={true} indicator={<LoadingOutlined spin className="text-custom-yellow" />} />
                        </div>
                    )}
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="absolute inset-0 w-full h-full object-cover"
                        onLoadedData={handleVideoLoad}
                        poster={dfimage}
                    >
                        <source src={videoBG} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    );
};

export default AroundTheWorld;
