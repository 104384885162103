import React from 'react';
import { Form, Input, Button } from 'antd';
import { PhoneOutlined, MailOutlined, EnvironmentOutlined } from '@ant-design/icons';

const ContactForm = () => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Form values:', values);
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-12 gap-10 px-4 md:px-20 md:py-10 bg-white">
            {/* Left Section */}
            <div className="col-span-1 md:col-span-6 flex flex-col gap-6">
                <h2 className="text-3xl md:text-5xl font-bold leading-tight">
                    Have Questions? <br /> Get in touch!
                </h2>
                <p className="text-gray-600 text-sm md:text-base w-full md:w-[60%]">
                    Please give us a call, drop us an email or fill out the contact form and we’ll get back to you.
                </p>
                <div className="flex flex-col gap-5 mt-5">
                    <div className="flex items-center gap-2 text-gray-600 text-sm">
                        <EnvironmentOutlined className="text-lg text-custom-blue" />
                        <span>785 15th Street, Office 478 Berlin</span>
                    </div>
                    <div className="flex items-center gap-2 text-gray-600 text-sm">
                        <PhoneOutlined className="text-lg text-custom-blue" />
                        <span className='font-bold'>+1 840 841 25 69</span>
                    </div>
                    <div className="flex items-center gap-2 text-gray-600 text-sm">
                        <MailOutlined className="text-lg text-custom-blue" />
                        <span>info@email.com</span>
                    </div>
                </div>
            </div>

            {/* Right Section - Contact Form */}
            <div className="col-span-1 md:col-span-6">
                <Form
                    form={form}
                    name="contact"
                    layout="vertical"
                    onFinish={onFinish}
                    className="shadow-md p-6 rounded-md"
                >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Form.Item
                            name="name"
                            label={<span className='text-sm font-medium'>Name</span>}
                            rules={[{ required: true, message: 'Please enter your name' }]}
                        >
                            <Input className='p-2 outline-none shadow-none focus:shadow-none border-t-0 rounded-none border-x-0 text-sm' />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            label={<span className='text-sm font-medium'>Email Address</span>}
                            rules={[
                                { required: true, message: 'Please enter your email address' },
                                { type: 'email', message: 'Please enter a valid email' },
                            ]}
                        >
                            <Input className='p-2 outline-none shadow-none focus:shadow-none border-t-0 rounded-none border-x-0 text-sm' />
                        </Form.Item>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Form.Item
                            name="phone"
                            label={<span className='text-sm font-medium'>Phone</span>}
                            rules={[{ required: true, message: 'Please enter your phone number' }]}
                        >
                            <Input className='p-2 outline-none shadow-none focus:shadow-none border-t-0 rounded-none border-x-0 text-sm' />
                        </Form.Item>

                        <Form.Item
                            name="subject"
                            label={<span className='text-sm font-medium'>Subject</span>}
                            rules={[{ required: true, message: 'Please enter the subject' }]}
                        >
                            <Input className='p-2 outline-none shadow-none focus:shadow-none border-t-0 rounded-none border-x-0 text-sm' />
                        </Form.Item>
                    </div>

                    <Form.Item
                        name="message"
                        label={<span className='text-sm font-medium'>How can we help you?</span>}
                        rules={[{ required: true, message: 'Please enter your message' }]}
                    >
                        <Input.TextArea rows={4} className='p-2 outline-none shadow-none focus:shadow-none border-t-0 rounded-none border-x-0 text-sm' />
                    </Form.Item>

                    <div className='flex items-center justify-start'>
                        <Form.Item>
                            <button type="submit" className="bg-custom-yellow w-full px-3 py-2 font-medium rounded-sm shadow-sm hover:bg-custom-blue transition-all duration-200 text-white">
                                Get in Touch
                            </button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default ContactForm;
