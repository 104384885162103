import React from 'react';
import { LuMoveRight } from 'react-icons/lu';

const Quality = () => {
    let data = [
        { head: "Experienced Team", desc: "Over 25 years in the industry with a deep understanding of global and regional markets." },
        { head: "Extensive Network", desc: "Strong connections and partnerships across Pakistan, UAE, India, and beyond." },
        { head: "Comprehensive Services", desc: "A wide range of services to meet diverse container needs." },
        { head: "Commitment to Quality", desc: "Rigorous standards to ensure only the best containers and services are offered." },
    ];

    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 h-auto min-h-[85vh] bg-fixed bg-center bg-cover services_bg'>
            {data.map((x, i) => (
                <div key={i} className='flex flex-col justify-end py-6 sm:py-10 px-4 h-full gap-3 sm:gap-5 relative group'>
                    {/* Apply backdrop blur for mobile/tablet and on hover for desktop */}
                    <div className='absolute inset-0 transition-all duration-300 backdrop-blur-md lg:backdrop-blur-none lg:group-hover:backdrop-blur-md'></div>
                    
                    {/* Number and Heading */}
                    <div className='z-[10] flex flex-col gap-1 sm:gap-2 translate-y-0 lg:translate-y-16 lg:group-hover:translate-y-0 transition-all duration-300'>
                        <div className='text-[14px] sm:text-[16px] text-custom-yellow font-semibold'>
                            {(i + 1).toString().padStart(2, "0")}
                        </div>
                        <div className='text-white text-[14px] sm:text-[20px] md:text-[24px] font-bold'>
                            {x.head}
                        </div>
                    </div>
                    
                    {/* Description: Always visible on mobile/tablet, hover effect for desktop */}
                    <div className='z-[10] text-white font-normal md:font-medium text-[10px] md:text-[15px] opacity-100 lg:opacity-0 lg:group-hover:opacity-100 lg:transition-opacity duration-300 group-hover:duration-[1500ms]'>
                        {x.desc}
                    </div>

                    {/* Arrow Button */}
                    <div className='z-[10] bg-[#ffffff4b] cursor-pointer hover:bg-custom-yellow transition-all rounded-full p-2 w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center'>
                        <LuMoveRight className='text-white text-[20px] sm:text-[30px]' />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Quality;
