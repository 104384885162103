import React from 'react'
import { BsClipboard2Pulse } from 'react-icons/bs'
import { GiBattleship, GiOffshorePlatform, GiWorld } from 'react-icons/gi'
import { HiOutlineHome } from 'react-icons/hi'
import { LuContainer } from 'react-icons/lu'
import CustomButton from '../../../components/buttons'
import { RiShipLine } from 'react-icons/ri'
import { HiOutlineClipboardDocumentCheck } from 'react-icons/hi2'
import { GoGear } from 'react-icons/go'

const WhatWeDo = ({ page = "default" }) => {

    let data = [
        { name: "Buying and Selling of Empty Containers", Icon: LuContainer, desc: "We offer a range of services designed to meet your container needs both domestically and globally. Our expertise spans various types of containers, ensuring you find the right fit for your requirements." },
        { name: "Global Container Sourcing", Icon: GiWorld, desc: "Efficiently source empty containers from around the world, with a special focus on Pakistan, UAE, and India." },
        { name: "Container Inspection and Certification", Icon: BsClipboard2Pulse, desc: "Purchase empty containers locally within Pakistan, UAE, and India with quick delivery and competitive pricing." },
        { name: "Domestic Container Sales", Icon: HiOutlineHome, desc: "Buy empty containers from our global network with streamlined international shipping solutions." },
        { name: "International Container Sales", Icon: RiShipLine, desc: "Flexible leasing options for businesses needing temporary container solutions, available both domestically and internationally." },
        { name: "Container Leasing Solutions", Icon: HiOutlineClipboardDocumentCheck, desc: "Tailor-made solutions for specific container needs, including modifications and customizations." },
        { name: "Custom Container Solutions", Icon: GoGear, desc: "Secure and convenient storage solutions for empty containers, available in key locations across Pakistan, UAE, and India." },
        { name: "Container Storage Services", Icon: GiOffshorePlatform, desc: "Professional inspection and certification services to ensure containers meet industry standards and are seaworthy." },
        { name: "Logistics and Delivery Coordination", Icon: GiBattleship, desc: "Comprehensive logistics support for the smooth delivery of containers to your desired location, both domestically and globally." },
    ]


    let ServiceContainer = ({ Icon, name, desc = "" }) => {
        return (
            page === "service"
                ? <div className='flex flex-col gap-3 shadow-lg hover:shadow-custom-blue p-5 group hover:bg-custom-blue transition-all duration-500'>
                    <span className='h-[40px]'>
                        <Icon className="text-custom-yellow transition-all duration-500  text-[30px] mr-[10px] lg:mr-0 md:mr-0 sm:mr-0" />
                    </span>
                    <span className='text-[14px] md:text-[16px] font-bold h-10 flex items-center group-hover:text-white transition-all duration-500'>{name}</span>
                    <div className='flex items-center justify-end'>
                        <span className='w-[80%] h-[1px] mr-[-20px] bg-[#b0b3bc77] group-hover:bg-custom-yellow transition-all duration-500'></span>
                    </div>
                    <span className='text-[12px] md:text-sm group-hover:text-white transition-all duration-500'>{desc}</span>
                </div>
                : <div className={`
                    flex flex-col gap-2 md:gap-10 lg:gap-20 justify-between group
                    md:border-none border-r border-r-[#dbdbdb] last:border-r-0 cursor-pointer
                    items-start
                     md:bg-white lg:bg-white
                    text-white sm:text-white md:text-black lg:text-black
                    shadow-lg sm:shadow-lg md:shadow-none lg:shadow-none
                    rounded-lg p-2 sm:p-2 md:p-0 lg:p-0
                    `}>

                    {/* Icon Section */}
                    <span className='h-[40px]'>
                        <Icon className={`
                            text-custom-yellow transition-all duration-200 group-hover:text-custom-blue
                            lg:text-[80px] md:text-[40px] sm:text-[40px] text-[30px]
                            mr-[10px] lg:mr-0 md:mr-0 sm:mr-0
                            `} />
                    </span>

                    {/* Name Section */}
                    <span className={`
                        text-[15px] sm:text-[15px] md:text-[18px] lg:text-[20px] font-bold h-[30px]
                        text-black
                        `}>
                        {name}
                    </span>

                    {/* Button Section */}
                    <button className={`
                        transition-all duration-300 group-hover:ml-2
                        text-[8px] md:text-[14px] font-medium text-start
                        text-black
                        `}>
                        Read More →
                    </button>
                </div>



        )
    }

    return (
        <div className='flex flex-col gap-10 p-5 sm:p-10 md:p-16 lg:p-20'>
            {/* Heading Section */}
            <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                <div className='flex flex-col gap-1'>
                    <span className='text-[8px] md:text-[12px] font-bold'>{page === "default" ? "WHAT WE DO" : "WE OFFER YOU"} </span>
                    <span className='text-[20px] sm:text-[34px] md:text-[40px] lg:text-[44px] font-bold w-full md:w-[80%] leading-tight'>
                        {page === "default" ? "Global Leaders in Empty Container Trading" : "Few Reasons Why You Choose Us."}
                    </span>
                </div>
                <div className='text-[12px] sm:text-base'>
                    {page === "default" ? "With over 25 years in the container trading industry, we’ve established ourselves as a leading provider of high-quality, reliable containers." : "We offer a range of services designed to meet your container needs both domestically and globally. Our expertise spans various types of containers, ensuring you find the right fit for your requirements."}  <br /><br />
                    Our extensive experience ensures that we deliver top-notch service and solutions tailored to your needs.
                </div>
            </div>

            {/* Services Section */}
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 lg:gap-10 mt-5'>
                {page === "default"
                    ? data.slice(0, 4).map((item, index) => (
                        <ServiceContainer key={index} name={item.name} Icon={item.Icon} />
                    ))
                    : data.map((item, index) => (
                        <ServiceContainer key={index} name={item.name} Icon={item.Icon} desc={item.desc} />
                    ))
                }
            </div>

            {page === "default" ? <div className='flex items-center justify-center mt-10'>
                <CustomButton text='View All Services' type='blue' />
            </div> : <></>}
        </div>
    )
}

export default WhatWeDo
