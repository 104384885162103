import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import CustomButton from '../../../components/buttons';

const SliderSection = () => {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 5000,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const items = [
        {
            head: "Logistics Solutions Provider",
            button: "Explore Now",
            image: "image_one",
            desc: "We know everything about the safest and the fastest shipping. We help you build your successful business."
        },
        {
            head: "Trade Without Boundaries",
            button: "Services",
            image: "image_two",
            desc: "Your gateway to seamless international trade."
        },
        {
            head: "Modern Logistics",
            button: "Explore Now",
            image: "image_three",
            desc: "Embracing technology for efficient logistics."
        }
    ];

    // Handle parallax effect on scroll
    const getBackgroundStyle = (index) => {
        const parallaxValue = scrollPosition * 0.2; // Control parallax intensity
        return {
            backgroundPosition: `center`
        };
    };

    const ItemsContainer = ({ head, button, image, index, desc }) => {
        const [isVisible, setIsVisible] = useState(false); // Control visibility state

        useEffect(() => {
            const timer = setTimeout(() => {
                setIsVisible(true); // Show after 2 seconds
            }, 500);

            return () => clearTimeout(timer); // Cleanup the timeout when unmounting
        }, [currentSlide]); // Re-trigger on slide change



        return (
            <div
                className={`relative h-screen bg-cover bg-center transition-opacity flex justify-center ${image}`}
                style={getBackgroundStyle(index)}
            >
                <div className="absolute inset-0 bg-black bg-opacity-30"></div>
                {isVisible && ( // Only show content after 2 seconds
                    <div className="relative z-10 flex flex-col gap-10 justify-center items-center text-center h-full text-white px-4 animate-fadeInUp w-[100%] md:w-[60%] lg:w-[60%]">
                        <h1 className="text-[25px] leading-6 md:leading-normal md:text-[55px] font-bold uppercase">{head}<span className='text-[50px] text-custom-yellow'>.</span></h1>
                        <span className='text-[12px] md:text-[15px] w-[80%] md:w-[50%] mt-[-20px] text-white text-center'>{desc}</span>
                        <CustomButton text={button} type="yellow" />
                    </div>
                )}
            </div>
        );
    };

    const handleSlideClick = (index) => {
        sliderRef.current.slickGoTo(index);
    };


    return (
        <section className="relative h-screen">
            <Slider ref={sliderRef} {...settings}>
                {items.map((x, i) => (
                    <ItemsContainer key={i} head={x.head} button={x.button} image={x.image} index={i} desc={x.desc} />
                ))}
            </Slider>

            <div className="absolute bottom-5 left-0 right-0 text-center text-white">
                {items.map((_, index) => (
                    <span
                        key={index}
                        onClick={() => handleSlideClick(index)}
                        className={`mx-3  rounded-full text-[8px] md:text-[11px] relative ${currentSlide === index ? 'text-custom-yellow font-bold' : 'text-white'}`}
                    >
                        {index + 1}
                    </span>
                ))}
            </div>
        </section>
    );
};


// Custom Next Arrow Component
const NextArrow = (props) => {
    return (
        <button
            className="absolute right-20 transform top-1/2 -translate-y-1/2 p-4 text-white rounded-full z-10 transition-opacity duration-300 opacity-70 hover:opacity-100 hidden md:block" // Hide on mobile
            onClick={props.onClick}
        >
            <SlArrowRight size={50} />
        </button>
    );
};

// Custom Prev Arrow Component
const PrevArrow = (props) => {
    return (
        <button
            className="absolute left-20 transform top-1/2 -translate-y-1/2 p-4 text-white rounded-full z-10 transition-opacity duration-300 opacity-70 hover:opacity-100 hidden md:block" // Hide on mobile
            onClick={props.onClick}
        >
            <SlArrowLeft size={50} />
        </button>
    );
};



export default SliderSection;
